import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'start', loadChildren: './start/start.module#StartPageModule' },
  { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'stations', loadChildren: './tabs/pages/stations/stations.module#StationsPageModule' },
  { path: 'instellingen', loadChildren: './tabs/pages/instellingen/instellingen.module#InstellingenPageModule' },
  { path: 'stationdetails', loadChildren: './tabs/pages/stationdetails/stationdetails.module#StationdetailsPageModule' },
  { path: 'literdetails', loadChildren: './tabs/pages/literdetails/literdetails.module#LiterdetailsPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
