import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.storage.set('tutorialComplete', false);
      this.storage.get('tutorialComplete')
      .then( data => {
        if (data) {
          this.router.navigate(["/tabs"]);
        } else {
          this.router.navigate(["/start"]);
        }
        this.splashScreen.hide();
      }, err => {
        this.router.navigate(["/start"]);
        this.splashScreen.hide();
      })
      this.statusBar.styleBlackTranslucent();
    });
  }
}
